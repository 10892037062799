@import 'node_modules/@trato-logistica/trato-web-ui/assets/styles/shadow.scss';
@import 'node_modules/@trato-logistica/trato-web-ui/assets/styles/colors.scss';
@import 'node_modules/@trato-logistica/trato-web-ui/assets/styles/variables.scss';
@import 'node_modules/@trato-logistica/trato-web-ui/assets/styles/tokens/font.scss';

.display {
  --orangePrimary: #{$orange-primary};
}

.opened {
  --orangePrimary: #{$orange-primary};
}

.clusterClass > div > span {
  color: $white;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica';
}

.navbar-user-actions .trato-menu-panel {
  @extend .shadow-card;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  position: absolute;
  top: 5.3rem;
  right: 0rem;
  width: 16rem;
  min-height: 4.4rem;
  z-index: 90;

  @media #{$medium-and-up} {
    top: 7rem;
    width: 20rem;
    min-height: 6.4rem;
  }

  .trato-menu-content {
    .trato-menu-item {
      height: 2.8rem;
      @media #{$medium-and-up} {
        height: 4.8rem;
      }
    }
  }
}

.list-empty {
  .trato-blankslate {
    min-height: 50rem;
  }
}

.uppercase {
  text-transform: uppercase;
  &::-moz-placeholder,
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-webkit-input-placeholder,
  &::placeholder,
  .ng-placeholder {
    text-transform: none;
  }
}

.tooltip-batch-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  z-index: 1000;
  pointer-events: none;
  color: $white;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-xxxs;
  text-align: center;
}

@media print {
  html {
    background-color: $white !important;
  }
}

trato-modal#modalImport {
  .trato-modal {
    z-index: 999 !important;

    .uploaded__file-info {
      overflow: hidden;
    }

    trato-icon {
      align-self: center;
    }
  }
}

.section {
  @media screen and (min-width: 541px) {
    margin-top: 4rem;
  }
}

.over-auto {
  overflow: auto;
}

.button-action {
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 4rem;
  @media #{$medium-and-down} {
    justify-content: center;
  }
}

.tf-v1-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
  }

  .tf-v1-iframe-wrapper {
    z-index: 1002;
    width: 70% !important;
    transform: translate(-50%, -50%) !important;
    position: relative;
    height: 75% !important;
    left: 50%;
    top: 50%;

    iframe {
      width: 100%;
      height: 100%;
    }

    .tf-v1-close {
      position: absolute;
      right: 20px;
      top: 20px;
      border: 0;
      background: transparent;
      font-size: 40px;
    }
  }
}

.align-title-roles {
  display: flex;
  align-items: center;

  .trato-checkbox__text,
  .counter {
    color: #adb5bc !important;
  }
}

%modal-highlight {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

trato-modal-body span.highlight {
  @extend %modal-highlight;
}

trato-modal-body span.primary-highlight {
  @extend %modal-highlight;
  color: $orange-primary;
}

.terminal-filter .trato-checkbox-button__container.checked{
  background: $purple-light !important;
  border: 1px solid $purple !important;
  svg {
    fill: $purple;
  }
}

.schedule-filter .trato-checkbox-button__container.checked {
  background: $blue-light !important;
  border: 1px solid $blue !important;
  svg {
    fill: $blue;
  }
}
